import { inject, Injectable } from "@angular/core";
import { ArticlesApi, ApiArticleFranchise, ApiFranchise, ApiArticle, ApiTopicItem, ApiSearchResult } from '@tytapp/api';
import { Cache, PagedArray } from "@tytapp/common";
import { dateToUrl } from '@tytapp/common';
import { Shell } from '@tytapp/common';
import { environment } from '@tytapp/environment';

@Injectable()
export class ArticlesService {
    private articlesApi = inject(ArticlesApi);
    private shell = inject(Shell);

    cache: Cache<ApiArticle> = Cache.shared<ApiArticle>('articles', 1000 * 60 * 15, 100);
    feedCache: Cache<PagedArray<ApiArticle>> = Cache.shared<PagedArray<ApiArticle>>('article-feeds', 1000 * 60 * 15, 20);

    async getFranchiseUrl(franchise: ApiFranchise, includeOrigin = false) {
        await this.shell.waitForFeatures();
        return this.getFranchiseUrlSync(franchise, includeOrigin);
    }

    getFranchiseUrlSync(franchise: ApiFranchise, includeOrigin = false) {
        let url = `/${franchise.show_in_original_reporting ? 'reports' : 'stories'}/${franchise.identifier ?? franchise.id}`;
        if (includeOrigin)
            url = `${environment.urls.root}${url}`;

        return url;
    }

    async getArticleUrl(article: ApiArticle, includeOrigin = false) {
        await this.shell.waitForFeatures();
        return this.getArticleUrlSync(article, includeOrigin);
    }

    getArticleUrlSync(article: ApiArticle, includeOrigin = false) {
        if (!article)
            return null;

        let useDateUrls = this.shell.hasFeatureSync('apps.web.articles.redirect_date_url');
        let franchiseSlug = article.franchise?.identifier ?? article.franchise?.id ?? '-';
        let dateSlug = (useDateUrls && article.publish_date) ? dateToUrl(article.publish_date) : null;
        let articleSlug = encodeURIComponent(article.slug || article.id);
        let area = 'reports';

        if (article.franchise && !article.franchise.show_in_original_reporting)
            area = 'stories';

        let url: string;
        if (dateSlug)
            url = `/${area}/${franchiseSlug}/${dateToUrl(article.publish_date)}/${articleSlug}`;
        else
            url = `/${area}/${franchiseSlug}/${articleSlug}`;

        if (includeOrigin)
            url = `${environment.urls.root}${url}`;

        return url;
    }

    async getArticleUrlFromTopicItem(topicItem: ApiTopicItem, includeOrigin = false) {
        await this.shell.waitForFeatures();
        return this.getArticleUrlFromTopicItemSync(topicItem, includeOrigin);
    }

    getArticleUrlFromTopicItemSync(topicItem: ApiTopicItem, includeOrigin = false) {
        if (!topicItem)
            return null;

        let useDateUrls = this.shell.hasFeatureSync('apps.web.articles.redirect_date_url');
        let url = `/reports/${topicItem.article_id}`;

        if (useDateUrls && topicItem.date)
            url = `/reports/${topicItem.brand_id ?? '-'}/${dateToUrl(topicItem.date)}/${topicItem.article_id}`;
        else
            url = `/reports/${topicItem.brand_id}/${topicItem.article_id}`;

        if (includeOrigin)
            url = `${environment.urls.root}${url}`;

        return url;
    }

    async all(offset?, limit?) {
        return await this.feedCache.fetch(`all-page-${offset}-${limit}`, async skip => {
            return await this.articlesApi.all(offset, limit).toPromise();
        });
    }

    async franchiseFeed(franchiseID, offset?, limit?) {
        return await this.feedCache.fetch(`franchise-${franchiseID}-page-${offset}-${limit}`, async skip => {
            return await this.articlesApi.getArticlesByFranchise(franchiseID, offset, limit).toPromise();
        });
    }

    async latestByFranchise(): Promise<PagedArray<ApiArticleFranchise>> {
        return <any>await this.feedCache.fetch(`latest-by-franchise`, async () => {
            return <any>await this.articlesApi.getLatestByFranchise().toPromise();
        });
    }

    async get(id: string) {
        // No caching on articles until we can reliably determine entitlement app-server-side
        return await this.articlesApi.get(id).toPromise();
    }

    async getArticleUrlFromSearchResult(item: ApiSearchResult) {
        await this.shell.waitForFeatures();
        return this.getArticleUrlFromSearchResultSync(item);
    }

    getArticleUrlFromSearchResultSync(item: ApiSearchResult) {
        return `/reports/${
            item.group_slug || item.group_uuid
        }/${
            dateToUrl(new Date(item.date)?.toISOString())
        }/${
            item.slug || item.uuid
        }`;
    }
}
