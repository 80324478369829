import { Component, ElementRef, Input, inject } from "@angular/core";
import { ApiArticle, ApiTalent, ApiUser } from "@tytapp/api";
import { IFrameResizer, Shell } from '@tytapp/common';
import { CONTENT_TYPE_HELPER } from '@tytapp/content';
import { isServerSide } from '@tytapp/environment-utils';
import { UserService } from "@tytapp/user";
import { SubSink } from "subsink";

@Component({
    selector: 'tyt-article',
    templateUrl: './article-view.component.html',
    styleUrls: ['./article-view.component.scss']
})
export class ArticleViewComponent {
    private element = inject(ElementRef);
    private userService = inject(UserService);
    private shell = inject(Shell);
    private iframeResizer = inject(IFrameResizer);

    private _article: ApiArticle;
    private _subs = new SubSink();

    readonly ContentTypes = CONTENT_TYPE_HELPER;

    async ngOnInit() {
        this._subs.add(this.userService.userChanged.subscribe(u => this.user = u));
        this.isOpenBeta = await this.shell.hasFeature('apps.web.nation_open_beta');
    }

    ngOnDestroy() {
        this._subs.unsubscribe();
    }

    @Input()
    get article(): ApiArticle { return this._article; }
    set article(value) {
        this._article = value;
        setTimeout(() => this.doInit());
    }

    user: ApiUser;

    @Input()
    allowEditor = false;

    get showEditLink() {
        return this.allowEditor && this.user && this.user.staff;
    }

    resizeFrames() {
        if (isServerSide())
            return;

        setTimeout(() => {
            let el: HTMLElement = this.element.nativeElement;
            let iframes = el.querySelectorAll('iframe');
            for (let i = 0; i < iframes.length; ++i) {
                let element = iframes.item(i);
                if (element.classList.contains('resizable'))
                    this.iframeResizer.install(iframes.item(i));
            }
        }, 500);
    }

    private doInit() {
        this.resizeFrames();
    }

    get author(): ApiTalent {
        if (!this.article)
            return null;

        return this.article.talent[0];
    }

    get otherAuthors(): ApiTalent[] {
        if (!this.article || this.article.talent.length <= 1)
            return null;

        return this.article.talent.slice(1);
    }

    isOpenBeta: boolean;
    get shouldShowTalentUser() {
        if(!this.user) return false;
        if (this.isOpenBeta || (!this.isOpenBeta && this.user.can_access_tytnation)) {
            return true;
        }
        return false;
    }
}