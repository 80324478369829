import { Injectable } from "@angular/core";
import { ApiFranchise, FranchiseApi } from "@tytapp/api";
import { Cache, PagedArray } from "@tytapp/common";

@Injectable()
export class FranchisesService {
    constructor(
        private franchiseApi: FranchiseApi
    ) {
    }

    cache: Cache<ApiFranchise> = Cache.shared<ApiFranchise>('franchises', 1000 * 60 * 15, 100);
    listCache: Cache<PagedArray<ApiFranchise>> = Cache.shared<PagedArray<ApiFranchise>>('franchises-list', 1000 * 60 * 15, 100);

    async all() {
        return await this.listCache.fetch('default', async () => await this.franchiseApi.all().toPromise() as PagedArray<ApiFranchise>);
    }

    async get(id: string) {
        return await this.cache.fetch(id, async () => await this.franchiseApi.get(id).toPromise() as ApiFranchise);
    }

}